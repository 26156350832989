 <template>
  <div class="w-100">
    <form class="wrap-form">
      <div class="menu-edit-page w-100">
        <basic-subheader>
          <template v-slot:actions>
            <b-button class="btn ml-3" type="button" @click="returnPage">
              <span class="svg-icon">
                <inline-svg
                  src="/media/svg/icons/Neolex/Arrows/arrow-left.svg"
                />
              </span>
              Quay lại
            </b-button>
            <!-- <b-button
              class="btn btn-success ml-3"
              type="button"
              :disabled="loading"
              @click="GetData"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
              </span>
              Lưu
            </b-button> -->
          </template>
        </basic-subheader>
        <b-container fluid class="menu-edit-page__body mb-6 mt-6">
        </b-container>
      </div>
      <b-card>
        <h5 class="text-success mb-6">Thông tin chi tiết khóa học</h5>

        <b-col cols="4" style="min-height: 120px">
          <div class="image-upload">
            <v-file-input
              hide-input
              v-model="file"
              accept="image/png, image/jpeg"
              class="icon-edit"
              @change="onUpload"
              prepend-icon="mdi-pencil-outline"
              truncate-length="15"
            ></v-file-input>
            <v-img :src="preview" :aspect-ratio="2 / 1"></v-img>
          </div>
        </b-col>
        <b-row>
         

          <b-col cols="5">
            <basic-select
              label="Gói dịch vụ"
              placeholder="--- Chọn gói dịch vụ ---"
              track-by="id"
              value-label="name"
              :options="this.packagesName"
              :value.sync="selectedPackage"
              changeValueByObject
              required
              v-validate="'required'"
              disabled="true"
            />
          </b-col>

          <b-col cols="5">
            <basic-input
              label="Tên khóa"
              required
              placeholder="Nhập Tên khóa"
              name="group_name"
              v-validate="{
                required: true,
                alpha_num_dot: true,
                max: 30,
              }"
              v-model="group_name"
              disabled="true"

            />
          </b-col>

          <b-col cols="3">
            <basic-input
              label="Nhóm Zalo"
              required
              placeholder="Chỉ định tên nhóm Zalo"
              track-by="group_link"
              value-label="name"
              name="zalo_group_name"
              v-model="zalo_group_name"
              disabled="true"

            />
          </b-col>

          <b-col cols="3">
            <basic-input
              label="Mã khóa"
              required
              placeholder="Mã khóa"
              name="name"
              disable="true"
              v-validate="{
                required: true,
                alpha_num_dot: true,
                max: 30,
              }"
              v-model="this.selectedPackage.code"
              disabled="true"
            />
          </b-col>

          <b-col cols="4">
            <basic-input
              :id="`brithday-group`"
              label="Link nhóm Zalo chung"
              placeholder="Nhập link group Zalo chung"
              class="required"
              label-for="brithday-ID"
              v-model="link_zalo"
              disabled="true"
            >
            </basic-input>
          </b-col>

          <!-- drop down list-->
          <b-col cols="3">
            <basic-select
              label="Health Coach chính"
              required
              placeholder="Chỉ định coach cho khóa"
              track-by="id"
              value-label="fullNameSearch"
              name="coach"
              v-model="coach_id"
              :options="this.coachesList"
              :multiple="false"
              disabled="true"
            />
          </b-col>

          <b-col cols="3">
            <basic-select
              label="Health Coach phụ"
              required
              placeholder="Chỉ định coach cho khóa"
              track-by="id"
              value-label="fullNameSearch"
              name="coach"
              v-model="coach_support_id"
              :options="this.coachesList"
              :multiple="true"
              disabled="true"
            />
          </b-col>
          <!-- drop down list-->

          <b-col cols="1">
            <basic-input
              label="Số tuần"
              :allow-empty="false"
              v-model="selectedPackage.duration"
              required
              disabled="true"
              changeValueByObject
            />
          </b-col>

          <b-col cols="2">
            <label>Ngày bắt đầu khóa *</label>
            <date-picker
              id="datechose"
              label="Ngày bắt đầu khóa"
              format="DD-MM-YYYY"
              :allow-empty="false"
              placeholder="Chọn ngày bắt đầu"
              required="true"
              name="status"
              changeValueByObject
              v-model="startDate"
              @change="generateDates"
              disabled="true"
            />
          </b-col>

          <b-col cols="2">
            <basic-input
              label="Ngày kết thúc khóa"
              :allow-empty="false"
              v-model="formatDateEnd"
              required
              name="status"
              disabled="true"
              changeValueByObject
            />
          </b-col>

          <!-- <b-col cols="6">
            <basic-input
              :id="`brithday-group`"
              label="Link group health coach"
              placeholder="Nhập link group Zalo nhóm"
              class="required"
              label-for="brithday-ID"
              v-model="link_groupzalo"
              disabled="true"
            >
            </basic-input>
          </b-col> -->
        
        </b-row>

        <table class="weeksTable">
          <thead>
            <tr>
              <th>Tuần</th>
              <th>Ngày bắt đầu</th>
              <th>Ngày kết thúc</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(week, index) in weeksBreakdown" :key="index">
              <td>{{ week.weekNumber }}</td>
              <td>{{ formatDate(week.startDate) }}</td>
              <td>{{ formatDate(week.endDate) }}</td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </form>
  </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';

export default {
  name: 'CourseProfile',
  components: {
    'date-picker': DatePicker,
  },
  props: {
    coachesList: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      group_name: '',
      zalo_group_name: '',
      zalo_group_id: '',
      fileId: '',
      preview: '',
      courses_List: [],
      weeksBreakdown: [],
      logo: null,
      name: '',
      description: null,
      link_zalo: '',
      link_groupzalo: 'null',
      coach_id: '',
      coach_support_id: [],
      coachSupportList: [],
      coach_List: [],
      selectedPackage: '',
      selectedCoach: '',
      startDate: '',
      startDateOutput: '',
      endDateOutput: '',
      formatDateEnd:'',
      numWeeks: '',
      selectedCourseId: '',
      positions: [],
      statuses: [
        {
          name: 'Active',
          value: 1,
        },
        {
          name: 'Inactive',
          value: 0,
        },
        {
          name: 'Draft',
          value: 2,
        },
      ],
      packagesName: [],
      loading: false,
    };
  },
  watch: {},
  computed: {},
  async created() {
    await this.GetCourses();
    this.generateDates();
  },
  mounted() {},
  methods: {
     formatDateMinusOneDay(date) {
        // Convert the input date to a Date object
        var dateObj = new Date(date);
        // Subtract one day
        dateObj.setDate(dateObj.getDate() + 1);
        
        // Extract day, month, and year
        var day = ("0" + dateObj.getDate()).slice(-2);
        var month = ("0" + (dateObj.getMonth() + 1)).slice(-2); // Months are 0-based, so add 1
        var year = dateObj.getFullYear();
        
        return `${day}-${month}-${year}`;
    },
    formatDate(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  },
    convertTimestampToFormattedDate(timestamp) {
      if (timestamp === null || timestamp === undefined) {
        return null;
      }

      try {
        const date = new Date(timestamp * 1000);

        // Define the fixed time zone offset (GMT+0800)
        const timeZoneOffset = '+0800';

        // Format the date string
        const formattedDate = `${date.toDateString()} ${
          date.toTimeString().split(' ')[0]
        } GMT${timeZoneOffset} (Indochina Time)`;
        const dateConverted = new Date(formattedDate);
        return dateConverted;
      } catch (error) {
        return null;
      }
    },
    async setupPackage() {
      this.$store.commit('context/setLoading', true);

      try {
        this.coach_id = this.coachesList.find(
          (el) => el.id === this.courses_List.coachId,
        );

        this.coach_support_id = this.coachesList.filter((ez) =>
          this.coachSupportList.some(
            (coachSupport) => coachSupport.coachSupportInchargeId === ez.id,
          ),
        );

        this.selectedPackage = this.packagesName.find(
          (ex) => ex.id == this.courses_List.packageId,
        );

        this.selectedPackage.code = this.courses_List.name;
        this.selectedPackage.duration = this.courses_List.duration;
        this.startDate = this.convertTimestampToFormattedDate(
          this.courses_List.startDatetime,
        );
        // this.endDateOutput = this.courses_List.endDatetime;
       
        this.link_zalo = this.courses_List.linkZaloGroupCommon;
        this.group_name = this.courses_List.groupName;
        this.zalo_group_name = this.courses_List.zaloGroupName ;
        this.preview =
          process.env.VUE_APP_API + `/Image/${this.courses_List.logo}`;
      } catch (e) {
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: e.message,
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },

    dropDownWeeks() {
      let startDate = new Date(this.startDate);
      let endDate = new Date(this.endDateOutput);
      this.weeksBreakdown = this.getWeeksBreakdown(
        startDate.setDate(startDate.getDate() + 1),
        endDate,
      );
    },

    getWeeksBreakdown(startDate, endDate) {
      const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
      const totalDays = Math.round((endDate - startDate) / oneDay);
      const numWeeks = Math.ceil(totalDays / 7); // Round up to ensure complete weeks

      const weeks = [];
      let currentWeekStart = new Date(startDate);

      for (let week = 1; week <= numWeeks; week++) {
        const currentWeekEnd = new Date(currentWeekStart);
        currentWeekEnd.setDate(currentWeekEnd.getDate() + 6); // Set to Saturday

        weeks.push({
          weekNumber: week,
          startDate: currentWeekStart.toISOString().slice(0, 10),
          endDate: currentWeekEnd.toISOString().slice(0, 10),
        });

        currentWeekStart.setDate(currentWeekStart.getDate() + 7); // Move to next week
      }

      return weeks;
    },

    generateDates() {
      var numWeeks = this.selectedPackage.duration; // Or any number of weeks you want for the trial
      var startDate = new Date(this.startDate);
      var endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + numWeeks * 7);
      this.startDateOutput = startDate.toISOString().slice(0, 10);
      this.endDateOutput = endDate.toISOString().slice(0, 10);
      this.formatDateEnd = this.formatDate(endDate) ;
      this.dropDownWeeks();
    },

    convertTimestampToDatetime(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');

      const formattedDatetime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return formattedDatetime;
    },

    async GetData() {
      this.updateCourse();
    },
    returnPage() {
      this.$router.go(-1);
    },

    onUpload() {
      if (!this.file) return;
      this.isImage = ['image/jpeg', 'image/png'].includes(this.file.type);
      this.isImageValid = this.file.size <= 512000;
      if (this.isImage && this.isImageValid) {
        //  console.log(URL.createObjectURL(this.file));
        return (this.preview = URL.createObjectURL(this.file));
      }
      return (this.preview = null);
    },
    resetDropdown(field) {
      this.$validator.reset({ name: field });
    },

    // filterCourses(searchTerm) {
    //   this.filteredCourses = this.coach_id.filter(course => course.includes(searchTerm));
    // },
    async uploadImage(file) {
      // upload file
      const { error, data } = await this.$store.dispatch(
        'context/UPLOAD_IMAGE',
        file,
      );
      if (error) {
        this.$toastr.s({
          title: 'Lỗi !',
          msg: error,
        });
        return null;
      }

      return data.id;
    },

    async updateCourse() {
      this.$store.commit('context/setLoading', true);
      try {
        // Creating form data
        const formData = new FormData();
        if (this.file) {
          this.fileId = await this.uploadImage(this.file);
          if (!this.fileId) return;
          delete this.file;
        }
        formData.append('logo', this.fileId);
        formData.append('name', this.selectedPackage.code);
        formData.append('LinkZaloGroupCommon', this.link_zalo);

        if (typeof this.coach_id !== 'object' || this.coach_id === null) {
          formData.append('CoachId', this.coach_id);
        } else {
          formData.append('CoachId', this.coach_id.id);
        }

        if (this.endDateOutput && this.startDate) {
          formData.append(
            'StartDatetime',
            this.convertTimestampToDatetime(this.startDate),
          );
          formData.append(
            'EndDatetime',
            this.convertTimestampToDatetime(this.endDateOutput),
          );
        }

        formData.append('Duration', this.selectedPackage.duration);
        formData.append('PackageId', this.selectedPackage.id);

        // Sending the POST request with form data and Bearer token
        await this.$api.put(
          '/Courses/' + `${this.$route.params.id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );

        const formDataCoachSupport = new FormData();
        formDataCoachSupport.append('CoursesId', this.$route.params.id);

        await this.$api.put('/CoursesCoach', formDataCoachSupport, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        for (const items of this.coach_support_id) {
          const coursesCoachId = this.$uuid.v1();
          const formDataForCoachSupport = new FormData();

          formDataForCoachSupport.set('id', this.$uuid.v4());
          formDataForCoachSupport.append('id', coursesCoachId);
          formDataForCoachSupport.append('CoursesId', this.$route.params.id);

          if (typeof items !== 'object' || items === null) {
            formDataForCoachSupport.append('CoachSupportInchargeId', items);
          } else {
            formDataForCoachSupport.append('CoachSupportInchargeId', items.id);
          }

          const res_coursescoach = await this.$api.post(
            '/CoursesCoach',
            formDataForCoachSupport,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          );

          if (res_coursescoach.data.error) {
            return this.$toastr.e({
              title: 'Lỗi !',
              msg: res_coursescoach.data.error.message,
            });
          }
        }
      } catch (error) {
        // Handle any error that occurs during the request
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.$toastr.s({
          title: 'Thành công!',
          msg: 'Cập nhập thành công.',
        });
        this.$store.commit('context/setLoading', false);

        this.returnPage();
      }
    },

    async GetCourses() {
      this.$store.commit('context/setLoading', true);
      try {
        const { data, meta, error } = await this.$api.get(
          '/Courses/' + `${this.$route.params.id}`,
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.courses_List = data;
        await this.GetPackage();
        await this.GetCoachSupportList();
        await this.setupPackage();
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },

    async GetPackage() {
      await this.$api
        .get('/Package')
        .then((response) => {
          this.packagesName = response.data;
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    },

    async GetCoachSupportList() {
      await this.$api
        .get('/CoursesCoach/' + `${this.$route.params.id}`)
        .then((response) => {
          this.coachSupportList = response.data;
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    },
  },
};
</script>
<style lang="scss">
.image-upload {
  width: 240px;
  height: 120px;
  background-color: #f3f6f9;
  box-shadow: 0px 4px 4px 0px #000000 10%;
  position: relative;
  margin: auto;
}
.text-error {
  color: #ff5756;
  text-align: center;
  margin-top: 20px;
}
.pictureInput {
  position: relative;
}
.icon-edit {
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 18px);
  padding: 2px 6px;
  box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #fff;
}

.wrap-form {
  display: grid;
  // grid-template-columns: repeat(2, 1fr);
  column-gap: 32px;
}

.left-site {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  row-gap: 16px;
}

.blank {
  height: 16px;
  background: #f2f3f7;
  margin: 0 -30px 30px;
}
.mx-datepicker {
  margin-top: -3%;
}
#datechose {
  margin-top: 2px;
}

.weeksTable {
  width: 100%;
  border-collapse: collapse;
}

.weeksTable th,
.weeksTable td {
  border: 1px solid #ddd;
  padding: 8px;
}

.weeksTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.weeksTable th {
  background-color: #4caf50;
  color: white;
}
</style>
